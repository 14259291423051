import { openBottomSheet } from 'component/tmds/bottomSheet';
import { Paths, PaymentPaths } from 'constant/RoutePath';
import { COMPANY_NAME_MAP } from 'constant/Scooter';
import { PRE_PURCHASE_ERROR_CODE } from 'constant/Vehicle';
import { useCallback } from 'react';
import tripRepository from 'repository/tripRepository';
import vehicleRepository from 'repository/vehicleRepository';
import pmStore from 'store/pmStore';
import promotionStore from 'store/promotionStore';
import userInfoStore from 'store/userInfoStore';
import { ECompanyType, EServiceUserOS } from 'types/App';
import { EPaymentStatusCode, EQRScanStatus } from 'types/InApp';
import { TLonLat } from 'types/Map';
import { ERejectReason, EUserStep, RENT_REJECT_CODE } from 'types/api/User';
import { saveAFUserInfo, sendAFScanDevice, sendAFStartRide } from 'utils/appsFlyer';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import useCurrentPosition from './useCurrentPosition';
import useMovePage from './useMovePage';
import usePaymentPoint from './usePaymentPoint';
import { usePmUsingStatus } from './usePmUsingStatus';
import useUserInfo from './useUserInfo';

const usePmTripStart = () => {
  const userOs = ua.isAndroid ? EServiceUserOS.ANDROID : EServiceUserOS.IOS;
  const { movePage } = useMovePage();
  const { isActive: usePoint } = usePaymentPoint();
  const { getPosition } = useCurrentPosition();
  const { checkRequireLicense } = usePmUsingStatus();
  const { pmTermsAgree: termAgree, pmStatus, pmUserKey } = useUserInfo();

  const handleRentError = useCallback((err) => {
    const rejectCode = RENT_REJECT_CODE[err.response?.data?.code];

    if (rejectCode) {
      userInfoStore.setReject({ reason: rejectCode });
    } else {
      tmapWrapper.makeToast(err.response?.data?.message);
      userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
    }
  }, []);

  const openPrePurchaseModal = useCallback(async (errorCode: string, message?: string) => {
    const prePurchaseAction = await openBottomSheet({
      type: 'PRE_PURCHASE_ERROR',
      props: {
        code: errorCode,
        message,
      },
    });

    try {
      if (prePurchaseAction === 'purchase') {
        const isUpdateSuccess = await tmapWrapper
          .startPaymentActivity(PaymentPaths.CardUpdate)
          .then((result) => result[0] === EPaymentStatusCode.SUCCESS);
        if (isUpdateSuccess) {
          return 'retry';
        }
      }
    } catch (e) {
      return false;
    }

    return prePurchaseAction;
  }, []);

  const unlockDevice = useCallback(
    async (params: { company: ECompanyType; vehicleId: string }) => {
      const { company, vehicleId } = params;
      const orderInfo = await tripRepository.postTripOrderId();
      const orderId = orderInfo?.orderId;
      const promotionList = promotionStore.getState().myPromotions;
      const selectPromotionIndex = promotionStore.getState().selectedIndex;
      const currentPromotion =
        selectPromotionIndex !== undefined ? promotionList?.[selectPromotionIndex] : undefined;
      const eventKey = currentPromotion?.key;

      userInfoStore.setPmStatus({ step: EUserStep.WAIT_RIDING });
      const lonLat = (await getPosition()) as TLonLat;
      try {
        const data = await tripRepository.postTripStart({
          ...lonLat,
          company,
          vehicleId,
          userOs,
          orderId,
          usePoint,
          event: eventKey || undefined,
        });

        eventKey && promotionStore.completePromotion(eventKey);
        pmUserKey &&
          sendAFStartRide({
            riderId: pmUserKey,
            userStartLocation: lonLat,
          });
        saveAFUserInfo(data.tripId, { start: lonLat });

        if (data.event) {
          tmapWrapper.makeToast(
            `${COMPANY_NAME_MAP[company]} 할인 기기 탑승!\n잠금해제 비용이 할인됩니다.`
          );
        }

        userInfoStore.setPmStatus({
          step: EUserStep.RIDING,
          tripInfo: { tripId: data.tripId, company, vehicleId },
        });

        pmStore.setActiveItem(null);
        return true;
      } catch (err: any) {
        const code = err.response?.data?.code;

        if (code && PRE_PURCHASE_ERROR_CODE[code]) {
          const action = await openPrePurchaseModal(code, err.response.data.message);
          if (action === 'retry') {
            return unlockDevice({ company, vehicleId });
          }

          if (!action) {
            userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
            return false;
          }
        }
        handleRentError(err);
        return false;
      }
    },
    [getPosition, handleRentError, pmUserKey, openPrePurchaseModal, usePoint, userOs]
  );

  const startTrip = useCallback(
    async ({ company, vehicleId }, checkArea = true) => {
      if (checkArea) {
        try {
          const vehicleInfo = await vehicleRepository.checkVehicleReject(company, vehicleId);

          if (vehicleInfo.battery < 10) {
            userInfoStore.setReject({ reason: ERejectReason.LOW_BATTERY });
            return;
          }

          if (vehicleInfo.rejectInfo) {
            userInfoStore.setRentInfo({ company, vehicleId });

            userInfoStore.setReject({
              ...vehicleInfo.rejectInfo,
              reason: ERejectReason.DISABLE_RENT_AREA,
            });
            return;
          }
        } catch (err) {
          handleRentError(err);
          return;
        }
      }

      const isRequireLicense = await checkRequireLicense(company, vehicleId);
      if (isRequireLicense) {
        userInfoStore.setRentInfo({ company, vehicleId });
        movePage(Paths.PmLicense);
        return;
      }

      if (!termAgree?.[company]) {
        userInfoStore.setRentInfo({ company, vehicleId });
        window.setTimeout(() => {
          movePage(Paths.PmTermsAgree);
        }, 100);
        return;
      }

      userInfoStore.setPmStatus({ step: EUserStep.WAIT_RIDING, rentInfo: pmStatus?.rentInfo });

      const result = await unlockDevice({
        company,
        vehicleId,
      });

      return result;
    },
    [pmStatus?.rentInfo, checkRequireLicense, termAgree, unlockDevice, handleRentError, movePage]
  );

  const parseScanQR = useCallback(async (urlFromQR) => {
    if (urlFromQR) {
      try {
        const data = await vehicleRepository.getVehicleQr(urlFromQR);

        return data;
      } catch (err: any) {
        const rejectCode = RENT_REJECT_CODE[err?.response?.data?.code];
        userInfoStore.setReject({ reason: rejectCode || ERejectReason.INVALID_CODE });

        return { company: '', vehicleId: '' };
      }
    }

    return { company: '', vehicleId: '' };
  }, []);

  const scanVehicle = useCallback(async () => {
    const [scanStatus, qrCode] = await tmapWrapper.startQrCodeScanActivity('대여하기');

    if (scanStatus === EQRScanStatus.MANUAL) {
      setTimeout(() => {
        movePage(Paths.PmCodePage);
      }, 100);
    } else if (scanStatus === EQRScanStatus.COMPLETE) {
      const { company, vehicleId } = await parseScanQR(qrCode);
      sendAFScanDevice(company);
      return company && vehicleId && startTrip({ company, vehicleId });
    }
  }, [movePage, parseScanQR, startTrip]);

  return {
    enterCode: startTrip,
    userOs,
    scanVehicle,
  };
};

export default usePmTripStart;
