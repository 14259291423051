import { ReactNode } from 'react';
import { ReactComponent as IconTnow } from 'resource/images/icon_tnow.svg';
import { isInTime } from 'utils/date';
import fetcher from 'utils/fetcher';

import Store from './base';
import s from './promotionStore.module.scss';
import { localStorageStore, sessionStorageStore } from './storageStore';

export type Promotion = {
  key: string;
  startDate: string;
  endDate: string;
  text: ReactNode;
  title: string;
  saleContents: string;
};

type State = {
  promotions: Promotion[];
  currentPromotion?: Promotion;
  selectedIndex?: number;
  myPromotions?: Promotion[];
};

const promotions: Promotion[] = [
  {
    key: 'WHEREGO',
    startDate: '2024-10-02 00:00',
    endDate: '2024-12-31 23:59',
    text: (
      <>
        <IconTnow />
        <span className={s.wherego_text}>어디갈까</span>
        <span className={s.event_target}>이벤트 대상자</span>
      </>
    ),
    title: '어디갈까',
    saleContents: '1,000원 할인 적용',
  },
  {
    key: 'APOKI',
    startDate: '2024-10-02 00:00',
    endDate: '2024-12-31 23:59',
    text: (
      <>
        <span className={s.wherego_text}>APOKI</span>
        <span className={s.event_target}>이벤트 대상자</span>
      </>
    ),
    title: 'APOKI',
    saleContents: '잠금 해제 혜택 적용',
  },
  {
    key: 'UNLOCK_100_PER_MAXCOUNT_3_TMAP',
    startDate: '2022-01-01 00:00',
    endDate: '2099-12-31 23:59',
    text: (
      <>
        <span className={s.wherego_text}>잠금해제 무료</span>
        <span className={s.event_target}>이벤트 대상자</span>
      </>
    ),
    saleContents: '첫 잠금해제 3회 무료',
    title: '',
  },
];

class PromotionStore extends Store<State> {
  constructor() {
    super({ promotions, selectedIndex: 0 });

    promotions.forEach((it) => {
      sessionStorageStore.remove(it.key);
    });
  }

  joinPromotion = (key: string, userKey: string) => {
    const promotion = this.state.promotions.find((it) => it.key === key);
    if (!promotion) return;

    if (isInTime(promotion.startDate, promotion.endDate)) {
      localStorageStore.setValue(key, userKey);
    }
  };

  setMyPromotionList = (promotionList: Promotion[], userKey: string) => {
    this.updateState({ myPromotions: promotionList });
    promotionList.forEach((promotion) => {
      if (isInTime(promotion.startDate, promotion.endDate)) {
        sessionStorageStore.setValue(promotion.key, userKey);
        localStorageStore.setValue(promotion.key, userKey);
      }
    });
  };

  requestMyPromotions = async (userKey: string): Promise<Promotion[]> => {
    const promotions: Promotion[] = this.state.promotions.filter((it) => {
      if (!isInTime(it.startDate, it.endDate)) return false;
      const value = localStorage.getItem(it.key);
      if (!value?.includes(userKey)) return false;
      return true;
    });
    const codesString = promotions.map((it) => it.key).join(',');

    const response = await fetcher.get(`/api/event/checks?codes=${codesString}`);
    const resultData = response.data?.data || {};

    return promotions.filter((it) => resultData[it.key]);
  };

  clearAllPromotion = () => {
    this.state.promotions.forEach((it) => {
      sessionStorageStore.remove(it.key);
      localStorageStore.remove(it.key);
    });
  };

  completePromotion = (key: string) => {
    sessionStorageStore.remove(key);
    localStorageStore.remove(key);
    this.updateState({
      currentPromotion: undefined,
    });
  };

  setCurrentPromotion = (promotion?: Promotion) => {
    if (!promotion) {
      this.updateState({
        selectedIndex: undefined,
      });
      return;
    }

    const targetIndex = this.state.myPromotions?.findIndex((it) => it.key === promotion.key);
    if (targetIndex !== undefined && targetIndex >= 0) {
      this.updateState({
        selectedIndex: targetIndex,
      });
    }
  };
}

const promotionStore = new PromotionStore();

export default promotionStore;
